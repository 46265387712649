import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import ContentContainer from "../Core/ContentContainer"
import { Container, Row, Col } from "styled-bootstrap-grid"

const IntroContainer = styled(Container)`
  margin-top: 82px;
  margin-bottom: 68px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 100px;
    margin-bottom: 130px;
  }
`

const Intro = styled.h1`
  ${fonts.ralewayRegular};
  color: ${colors.darkGrey};
  font-size: 20px;
  letter-spacing: 6px;
  line-height: 26px;
  text-transform: uppercase;
  max-width: 744px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 32px;
    letter-spacing: 9.6px;
    line-height: 48px;
  }
`

const BlockIntro = ({ block }) => {
  return (
    <IntroContainer fluid>
      <ContentContainer>
        <Row>
          <Col md={11} lgOffset={1}>
            <Intro>{block.intro}</Intro>
          </Col>
        </Row>
      </ContentContainer>
    </IntroContainer>
  )
}

export default BlockIntro
