import React from 'react'
import styled from 'styled-components';
import breakpoints from "../../styles/breakpoints";
import Img from "gatsby-image";

const HeroContainer = styled.div`
  margin-bottom: 82px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 100px;
  }
`

const Desktop = styled.div`
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`
const Mobile = styled.div`
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const StyledImage = styled(Img)`
  width: 100%;
  height: 100%;
  max-height: 420px;
  @media (min-width: ${breakpoints.md}) {
    max-height: 500px;
  }
`

const Hero = ({block}) => {
  const {image: desktop, mobileHero: mobile} = block;
  
  return (
    <HeroContainer>
      <Desktop>
        <StyledImage fluid={desktop.fluid} alt={"About hero"} loading={"eager"}/>
      </Desktop>
      <Mobile>
        <StyledImage fluid={mobile.fluid} alt={"About hero"} loading={"eager"}/>
      </Mobile>
    </HeroContainer>
  )
}

export default Hero
