import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import PreFooter from "../components/Core/PreFooter"
import Hero from "../components/About/Hero"
import BlockTextImage from "../components/About/BlockTextImage"
import Intro from "../components/About/BlockIntro"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import BlockContainer from "../components/Core/BlockContainer"

const PrefooterContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    margin-top: -10px;
  }
`

const AboutPage = (props) => {
  const path = props.location.pathname
  const page = props.data.page
  let blocks = page.blocks

  if (!blocks) {
    blocks = []
  }

  let pageBlocks = []

  blocks.forEach((block, index) => {
    let blockType = block.__typename

    if (blockType === "ContentfulHero") {
      pageBlocks.push(<Hero key={index} block={block} />)
      return
    }
    if (blockType === "ContentfulBlockIntro") {
      pageBlocks.push(<Intro key={index} block={block} />)
      return
    }
    if (blockType === "ContentfulBlockTextImage") {
      pageBlocks.push(
        <BlockTextImage key={index} block={block} index={index} />
      )
      return
    }
  })

  return (
    <Layout darkStyle={false}>
      <SEO
        fullTitle={true}
        path={path}
        title={page.metaTitle}
        description={page.metaDescription}
      />
      <BlockContainer>
        {pageBlocks}
        <PrefooterContainer>
          <PreFooter />
        </PrefooterContainer>
      </BlockContainer>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutQuery {
    page: contentfulPage(slug: { eq: "agence" }) {
      title
      description
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on Node {
          ... on ContentfulHero {
            image {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            mobileHero {
              fluid(maxHeight: 1200, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          ... on ContentfulBlockIntro {
            intro
          }
          ... on ContentfulBlockTextImage {
            subHeader
            title
            imagePosition
            blockPosition
            content {
              json
            }
            image {
              fluid(maxWidth: 1814, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`
