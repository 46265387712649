import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import ContentContainer from "../Core/ContentContainer"
import { Container, Row, Col } from "styled-bootstrap-grid"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const StyledContainer = styled.div`
  margin-bottom: 70px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 150px;
  }
`

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  background: ${colors.backgroundGrey};
  background: ${(props) =>
    props.hovered ? colors.lightGold : colors.backgroundGrey};
  transition: 0.2s;
  position: absolute;
  top: -20px;
  right: -20px;
  @media (min-width: ${breakpoints.md}) {
    top: -28px;
    right: -28px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  max-height: ${(props) => (props.full ? "464px" : "369px")};
  max-width: ${(props) => (props.full ? "906px" : "332px")};
  @media (min-width: ${breakpoints.max}) {
    max-height: ${(props) => (props.full ? "564px" : "469px")};
    max-width: ${(props) => (props.full ? "1006px" : "432px")};
  }
`

const ColStyled = styled(Col)`
  margin-right: 20px;
  margin-top: 20px;

  @media (min-width: ${breakpoints.md}) {
    margin-right: 0;
    margin-top: 28px;
  }
`

const SubHeader = styled.div`
  ${fonts.galvjiRegular};
  color: ${colors.lightGold};
  font-size: 16px;
  letter-spacing: 0.03px;
  line-height: normal;
  margin-bottom: 5.5px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 18px;
    margin-bottom: 4px;
  }
  @media (min-width: ${breakpoints.max}) {
    margin-bottom: 8px;
  }
`

const Title = styled.h2`
  ${fonts.galvjiRegular};
  color: ${colors.darkGrey};
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 15.5px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 14px;
  }
`

const Content = styled.div`
  ${fonts.galvjiRegular};
  font-size: 14px;
  letter-spacing: 0.03px;
  line-height: 21px;
  color: ${colors.grey};
  a {
    color: ${colors.grey};
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const Info = styled.div`
  margin-top: 20px;
  @media (min-width: ${breakpoints.md}) {
    max-width: ${props => props.full ? "455px" : "360px"};
    margin-top: ${props => props.full ? "62px" : "0px"};
  }
  @media (min-width: ${breakpoints.max}) {
    max-width: ${props => props.full ? "560px" : "460px"};
  }
`

const ImgStyled = styled(Img)`
  @media (min-width: ${breakpoints.md}) {
    max-height: ${(props) => (props.full ? "464px" : "369px")};
    max-width: ${(props) => (props.full ? "906px" : "332px")};
  }
  @media (min-width: ${breakpoints.max}) {
    max-height: ${(props) => (props.full ? "564px" : "469px")};
    max-width: ${(props) => (props.full ? "1006px" : "432px")};
  }
`

const BlockTextImage = ({ block }) => {
  const {subHeader,title,content,image,imagePosition,blockPosition,} = block
  const offset =
    imagePosition === "Full Width" && blockPosition
      ? 0
      : imagePosition === "Full Width" && !blockPosition
      ? 2
      : blockPosition
      ? 1
      : 2;
  
  return (
    <StyledContainer>
      <ContentContainer>
        <Container fluid>
          <Row>
            <Col md={12} lg={10} lgOffset={offset}>
              <Row >
                <ColStyled
                  md={imagePosition === "Full Width" ? 12 : 6}
                  mdOrder={imagePosition === "Right" ? 2 : 1}
                  mdOffset={imagePosition === "Right" ? 1 : 0}
                >
                  <ImageContainer full={imagePosition === "Full Width"} >
                    <Overlay />
                    <ImgStyled fluid={image.fluid} full={imagePosition === "Full Width"}/>
                  </ImageContainer>
                </ColStyled>
                <Col
                  md={5}
                  alignSelf="center"
                  mdOrder={imagePosition === "Right" ? 1 : 2}
                  mdOffset={imagePosition === "Right"? 0 : imagePosition === "Left"? 1 : 2}
                  xlOffset={imagePosition === "Full Width" ? 2 : 0}
                >
                  <Info full={imagePosition === "Full Width"}>
                    <SubHeader>{subHeader}</SubHeader>
                    <Title>{title}</Title>
                    <Content>{documentToReactComponents(content.json)}</Content>
                  </Info>
                </Col>
              </Row>
            </Col>
          </Row>

        </Container>
      </ContentContainer>
    </StyledContainer>
  )
}

export default BlockTextImage
